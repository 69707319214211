import React, { useEffect } from "react";

import { graphql } from "gatsby"


import {Row,Card,Button } from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap-grid.min.css'


import Layout from "../components/layout"

import Accordion from 'react-bootstrap/Accordion'





function IndexPage() {

  useEffect(() => {

    window.location.replace("https://help.giftcard.dagcoin.org")

  }, [])

  return (

   <h1></h1>

    )

  }

  export const query = graphql`

  query($lang: String) {

    wordpressPage(

      slug: {eq: "support"}

      wpml_current_locale: {eq: $lang}

    ) {

      title

      yoast_meta {

        name

        content

        property

      }

      yoast_title

        wpml_current_locale

    }



  }

`

  export default IndexPage
